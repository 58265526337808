.container a {
    display: inline-block;
    z-index: 2;
    position: relative;
}
.container img {
    max-width: 220px;
}
@media (max-width: 991px) {
    .container {
        /* text-align: center; */
    }
    .container img {
        padding-top: 17px;
        max-width: 120px;
    }
}
