.title {
    font-size: 18px;
    text-transform: uppercase;
    padding: 0 20px;
    font-family: var(--font-hwp-bold);
    margin-bottom: 5px;
    margin-top: 30px;
}
.text {
    font-size: 14px;
    font-family: var(--font-replica);
    margin-bottom: 5px;
    padding: 0 20px;
}
.description {
    font-size: 14px;
    font-family: var(--font-replica);
    margin-bottom: 5px;
    padding: 0 20px;
}
.column {
    /*margin-bottom: 30px;*/
}
.columnLg {
    margin-bottom: 0;
}
.input {
    margin-bottom: 15px;
}
.formFooter {
    display: flex;
    flex-wrap: wrap;
    margin-top: 30px;
}
.formFooter > div {
    margin-right: 20px;
}
.formFooter > div:last-child {
    margin-right: 0;
}
.button {
    background: #000;
    color: var(--text);
    text-transform: uppercase;
    font-size: 40px;
    font-family: var(--font-t-semiBold);
    margin: 50px auto;
    max-width: 341px;
    height: 83px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
}
.button:hover {
    background: var(--text);
    color: var(--red);
}

@media (max-width: 991px) {
    .button {
        height: 55px;
        font-size: 30px;
    }
    .text {
        font-size: 14px;
    }
}
