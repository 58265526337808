:root {
    /*--red: #DF3E1F;*/
    --red: #f94803;
    --title: #fa5000;
    --inputBkg: rgba(0, 0, 0, 0.6);
    --inputBkgTransparent: rgba(248, 163, 40, 0.75);
    --text: #fff;
    --font-hwp-bold: "HWPanoTrialBold";
    --font-replica: "replicamono";
    --font-t-semiBold: "TungstenSemibold";
}
* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

html,
body {
    max-width: 100vw;
    overflow-x: hidden;
}

body {
    letter-spacing: 1px;
    color: var(--text);
}

a {
    color: inherit;
    text-decoration: none;
}
p {
    margin-top: 1em;
    margin-bottom: 1em;
}
input {
    letter-spacing: 1px;
    /*height: 55px;*/
    margin: 0 10px;
}
/* .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded {
    background: transparent !important;
} */
.MuiMenu-list {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    background: transparent;
}
/*@media(max-width: 991px){*/
/*  .bodyContainer{*/
/*    padding-top: 30px;*/
/*  }*/
/*}*/

.errorMessage {
    color: var(--text);
    margin-top: 5px;
    font-family: var(--font-replica);
}
ul {
    list-style: none;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}
