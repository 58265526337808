.container {
    position: relative;
    display: flex;
    min-height: 100vh;
    padding: 40px;
}
.imgCameo {
    height: 335px;
    position: absolute;
    right: 0;
    top: 80px;
    z-index: 3;
}
.body {
    position: relative;
    background-color: rgba(0, 0, 0, 0.87);
    display: flex;
    flex-direction: column;
    min-height: 100%;
    z-index: 2;
}

.logo {
    display: inline-block;
    position: relative;
    text-align: center;
    margin-top: -80px;
}
.logo img {
    max-width: 200px;
    /* transform: rotate(-45deg); */
}
.textContainer {
    flex: 1;
    display: flex;
    align-items: center;
}
.text {
    color: var(--text);
    font-size: 25px;
    font-family: var(--font-replica);
    margin: 30px auto;
    text-align: center;
    width: 70%;
}

@media (max-width: 991px) {
    .container {
        padding: 20px;
    }
    .imgCameo {
        z-index: 0;
    }
    .logo {
        margin-top: 0;
    }
    .text {
        font-size: 20px;
        width: 100%;
        margin: 0 20px;
    }
}
