.container {
    margin-top: 20px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
}
.label {
    padding-left: 18px;
    font-size: 14px;
    font-family: var(--font-replica);
    text-transform: uppercase;
}
.label a {
    cursor: pointer;
    text-decoration: underline;
}
@media (max-width: 991px) {
    .label {
        font-size: 14px;
    }
}
