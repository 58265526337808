.container {
    position: relative;
    background-color: var(--title);
    display: flex;
}
.imgLgLeft {
    position: relative;
    margin-left: -20px;
    flex: 1.2;
    padding-top: 30px;
}
.imgLgLeft img {
    max-width: 100%;
}
.group {
    flex: 1;
    position: relative;
    margin-left: -30px;
    padding-right: 27px;
}

.frameImg {
    /* position: absolute; */
    height: 100%;
}
.button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translatex(-50%);
    margin-top: 30px;
    display: block;
    width: 100%;
    text-align: center;
}
.button a {
    font-family: var(--font-hwp-bold);
    border: 2px solid #000;
    color: #000;
    cursor: pointer;
    text-transform: uppercase;
    padding: 12px 20px 8px;
    white-space: nowrap;
    display: inline-block;
    font-size: 1.4vw;
    transition: all 0.2s ease-in-out;
}
.button a:hover {
    background-color: #fff;
    color: var(--title);
    border-color: #fff;
}
.desktop {
    display: block;
}
.mobile {
    display: none;
}
@media (max-width: 991px) {
    .container {
        display: block;
    }

    .imgLgLeft {
        position: absolute;
    }
    .desktop {
        display: none;
    }
    .mobile {
        display: block;
    }
    .group {
        margin-left: 0;
        padding-right: 0;
        background-color: rgba(240, 108, 51, 0.87);
    }
    .button a {
        font-size: 2.7vw;
    }
}
