@font-face {
    font-family: "TungstenSemibold";
    src: url("./fonts/TungstenSemibold.eot");
    src: url("./fonts/TungstenSemibold.eot") format("embedded-opentype"),
        url("./fonts/TungstenSemibold.woff2") format("woff2"), url("./fonts/TungstenSemibold.woff") format("woff"),
        url("./fonts/TungstenSemibold.ttf") format("truetype"),
        url("./fonts/TungstenSemibold.svg#TungstenSemibold") format("svg");
}

@font-face {
    font-family: "replicamono";
    src: url("./fonts/replicamono.eot");
    src: url("./fonts/replicamono.eot") format("embedded-opentype"), url("./fonts/replicamono.woff2") format("woff2"),
        url("./fonts/replicamono.woff") format("woff"), url("./fonts/replicamono.ttf") format("truetype"),
        url("./fonts/replicamono.svg#replicamono") format("svg");
}

@font-face {
    font-family: "HWPanoTrialBold";
    src: url("./fonts/HWPanoTrialBold.eot");
    src: url("./fonts/HWPanoTrialBold.eot") format("embedded-opentype"),
        url("./fonts/HWPanoTrialBold.woff2") format("woff2"), url("./fonts/HWPanoTrialBold.woff") format("woff"),
        url("./fonts/HWPanoTrialBold.ttf") format("truetype"),
        url("./fonts/HWPanoTrialBold.svg#HWPanoTrialBold") format("svg");
}
