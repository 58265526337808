.container {
    background: #000000;
}
.paragraph {
    max-width: 90%;
    margin: auto;
    padding: 50px 0;
}
.title {
    font-size: 20px;
    line-height: 36px;
    font-family: var(--font-hwp-bold);
    color: var(--title);
}
.text {
    color: var(--text);
    font-size: 14px;
    font-family: var(--font-replica);
    margin-top: 30px;
}
.list li {
    margin-bottom: 10px;
    color: var(--text);
    font-size: 14px;
    font-family: var(--font-replica);
}
.list li:last-child {
    margin-bottom: 0;
}
.list a {
    color: var(--title);
}
.indexing {
    color: var(--title);
    padding-right: 5px;
}

.image {
    width: 100%;
}

@media (max-width: 991px) {
    .text {
        font-size: 14px;
    }
    .list li {
        font-size: 14px;
    }
    .image {
        margin-top: 20px;
    }
}
