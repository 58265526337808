.container {
    position: relative;
    min-height: 100vh;
}
.headerContainer {
    background-image: url("../../images/grey-texture.jpg");
    background-size: cover;
    background-position: center;
    padding-bottom: 90px;
}
.body {
    /* margin-top: 90px; */
    /* text-align: center; */
    position: relative;
    z-index: 2;
    background-image: url("../../images/register-bkg.jpg");
    background-position: center;
    background-size: cover;
}
.strips {
    position: absolute;
    left: 20px;
    top: 0;
    margin-top: -30px;
    background-image: url("../../images/black-strip.svg");
    background-repeat: repeat-y;
    width: 38px;
    height: 95%;
}
.logo {
    position: relative;
    margin-top: -100px;
    text-align: center;
}
.logo img {
    max-width: 200px;
}

.topBlock {
    width: 285px;
    background-color: #000;
    height: 25px;
    left: 0;
    margin-top: 40px;
    position: relative;
}
.topBlock:after {
    content: "";
    position: absolute;
    display: block;
    left: 100%;
    width: 0;
    height: 0;
    border-bottom: 25px solid #000;
    border-right: 25px solid transparent;
}
.topBlock:before {
    content: "";
    position: absolute;
    z-index: 1;
    display: block;
    bottom: 0;
    width: 87%;
    border-bottom: 1px solid #fff;
    left: 50%;
    transform: translateX(-50%);
}
.textContainer {
    background-color: #000;
    padding: 25px;
    position: relative;
    margin-bottom: 35px;
}
.text {
    font-size: 15px;
    text-align: center;
    color: var(--text);
    font-family: var(--font-replica);
}
.text p {
    margin: 0;
    max-width: 50%;
    margin: auto;
}
.imgWhiteStrip {
    width: 50%;
    position: absolute;
    bottom: 0;
    right: 0;
    margin-bottom: -5px;
}

@media (max-width: 991px) {
    .strips {
        display: none;
    }
    .headerContainer {
        padding-bottom: 30px;
    }
    .body {
        padding-top: 10px;
        /* margin-top: 90px; */
    }
    .logo {
        margin-top: -74px;
    }
    .logo img {
        max-width: 150px;
    }
    .topBlock {
        margin-top: 15px;
        width: 100px;
        height: 15px;
    }
    .text {
        font-size: 13px;
        /* margin-top: 30px; */
    }
    .text p {
        max-width: 100%;
    }
    .imgWhiteStrip {
        width: 60%;
        margin-bottom: -3px;
    }
}
